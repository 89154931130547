
import AssociateCompanyToUserModal from "@/components/AssociateCompanyToUserModal.vue";
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import ChangeUserStatusModal from "@/components/ChangeUserStatusModal.vue";
import CreateUserModal from "@/components/CreateUserModal.vue";
import DeleteUserModal from "@/components/DeleteUserModal.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import CompaniesSelector from "@/components/CompaniesSelector.vue";
import HandleUserAssociatingModal from "@/components/HandleUserAssociatingModal.vue";
import MakeUserTeacherModal from "@/components/MakeUserTeacherModal.vue";
import AssociateReferentToUserModal from "@/components/AssociateReferentToUserModal.vue";
import Modal from "@/components/Modal.vue";
import SuspendUserModal from "@/components/SuspendUserModal.vue";
import { RoutesNames } from "@/router";
import * as Api from "@/types/graphql";
import { checkUserRole, RoleCodes } from "@/utils/roles";
import { associateData, getReferentName } from "@/utils/user";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import {
  checkUserStatus,
  isDeleted,
  isSuspended,
  StatusCodes,
} from "../utils/status";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    Modal,
    MakeUserTeacherModal,
  },
  watch: {
    users(val, prev) {
      this.search = "";
      this.usersShown = val;
    },
    search(val) {
      this.filterUsers();
    },
    filters: {
      handler(val) {
        this.filterUsers();
      },
      deep: true,
    },
  },
})
export default class TeachersTable extends Vue {
  @State((state: StateStore) => state.auth.statusList) statusList;
  @State((state: StateStore) => state.data.users) users;
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Getter getRole: (code: string) => Api.Role;
  @Mutation showSuccess;
  @Mutation showError;
  @Mutation setSelectedUsers;

  loading = false;

  @Prop({ default: true })
  showSelect: boolean;
  @Prop({ default: true })
  showFilters: boolean;

  @Prop({ default: false })
  isSuspendedTable: boolean;

  usersShown = [];

  search = "";

  editingUser = null;

  sendingEmailUser = null;
  showSendingEmailModal = null;
  sendingEmailSubject = "";
  sendingEmailText = "";

  resettingPasswordUser = null;
  showResettingPasswordUserModal = null;

  showMakeUserTeacherModal = null;

  headers = [
    {
      text: "Utente",
      align: "start",
      sortable: true,
      value: "name",
    },
    {
      text: "Referente",
      align: "start",
      sortable: false,
      value: "referent",
    },
    {
      text: "Azienda",
      align: "start",
      sortable: false,
      value: "company",
    },
    {
      text: "Utente",
      align: "start",
      sortable: false,
      value: "status",
    },
    { text: "Azioni", value: "actions", sortable: false, filterable: false },
  ];
  selected = [];

  capitalize(text) {
    return _.capitalize(text);
  }

  initMakeTeacherUser(user) {
    this.editingUser = user;
    this.showMakeUserTeacherModal = true;
  }

  getRoleTypeLabel(user) {
    if (isSuspended(user.status?.code) || isDeleted(user.status?.code)) {
      if (checkUserRole(user, [RoleCodes.USER])) {
        return "Utente";
      }
      if (checkUserRole(user, [RoleCodes.ADMIN2])) {
        return "Amministratore Liv.2";
      }
    } else return user.status?.des;
  }

  setSending(item) {
    if (_.isNil(item)) {
      this.selected = [];
    } else {
      this.selected = [item];
    }
    this.showSendingEmailModal = item;
  }

  async sendEmail() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation sendEmailToUser(
          $guid: ID!
          $subject: String!
          $text: String!
        ) {
          sendEmailToUser(guid: $guid, subject: $subject, text: $text)
        }
      `,
      variables: {
        guid: this.selected[0].guid,
        subject: this.sendingEmailSubject,
        text: this.sendingEmailText,
      },
    });
    this.loading = false;
    this.setSending(null);
    if (res.data.sendEmailToUser) {
      this.showSuccess("Email inviata correttamente");
      this.sendingEmailSubject = "";
      this.sendingEmailText = "";
    } else {
      this.showError("Errore invio email");
    }
  }

  setResettingPassword(item) {
    this.resettingPasswordUser = item;
    this.showResettingPasswordUserModal = item;
  }

  handleClick(item) {
    this.$router.push({
      name: RoutesNames.teachersDetails,
      params: { userGuid: item.guid },
    });
  }

  async resetUserPassword() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation resetPassword($guid: ID!) {
          resetPassword(guid: $guid)
        }
      `,
      variables: { guid: this.resettingPasswordUser.guid },
    });
    this.loading = false;
    this.setResettingPassword(null);
    if (res.data.resetPassword) {
      this.showSuccess("Password reset completato");
    } else {
      this.showError("Errore reset password");
    }
  }

  hasReferentName(user) {
    return !_.isEmpty(getReferentName(user));
  }

  getReferentName(user) {
    if (this.hasReferentName(user)) {
      return getReferentName(user);
    }
    return "Nessun referente";
  }

  hasCompany(user) {
    return user.company != null;
  }

  getCompanyName(user) {
    if (this.hasCompany(user)) {
      return user.company.ragsoc;
    }
    return "Nessuna azienda collegata";
  }

  updateUsers() {
    this.$emit("update");
  }

  isSuspended(code) {
    return isSuspended(code);
  }

  isDeleted(code) {
    return isDeleted(code);
  }
}
