
import AdminsLiv2Table from "@/components/AdminsLiv2Table.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import CreateUserModal from "@/components/CreateUserModal.vue";
import TeachersTable from "@/components/TeachersTable.vue";
import * as Api from "@/types/graphql";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";

@Component({
  components: { Btn, Card, CreateUserModal, TeachersTable, AdminsLiv2Table },
})
export default class ManageTeachersView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @Action fetchRolesList;
  @Action getUsers;
  @Mutation setUsers;
  @Getter getRole: (code: string) => Api.Role;

  showCreateUserModal = null;

  async mounted() {
    this.fetchTeachers();
  }

  async fetchTeachers(){
    await this.getUsers({isTeacher: true})
  }
}
