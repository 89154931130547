
import Avatar from "@/components/Avatar.vue";
import Btn from "@/components/Btn.vue";
import Card from "@/components/Card.vue";
import ChangeUserStatusModal from "@/components/ChangeUserStatusModal.vue";
import CreateUserModal from "@/components/CreateUserModal.vue";
import DeleteUserModal from "@/components/DeleteUserModal.vue";
import Modal from "@/components/Modal.vue";
import UsersSelector from "@/components/UsersSelector.vue";
import SuspendUserModal from "@/components/SuspendUserModal.vue";
import AssociateReferentToUserModal from "@/components/AssociateReferentToUserModal.vue";
import { RoutesNames } from "@/router";
import * as Api from "@/types/graphql";
import { getReferentName } from "@/utils/user";
import gql from "graphql-tag";
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Getter, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import {
  checkUserStatus,
  isDeleted,
  isSuspended,
  StatusCodes,
} from "../utils/status";
import { RoleCodes } from "@/utils/roles";

@Component({
  components: {
    Card,
    Avatar,
    Btn,
    Modal,
    CreateUserModal,
    ChangeUserStatusModal,
    SuspendUserModal,
    DeleteUserModal,
    UsersSelector,
    AssociateReferentToUserModal,
  },
  watch: {
    users(val, prev) {
      this.search = "";
      this.usersShown = val;
    },
    search() {
      this.filterUsers();
    },
    filters: {
      handler(val) {
        this.filterUsers();
      },
      deep: true,
    },
  },
})
export default class AdminsLiv2Table extends Vue {
  @State((state: StateStore) => state.auth.statusList) statusList;
  @State((state: StateStore) => state.data.users) users;
  @Getter getStatus: (code: string) => Api.UserStatus;
  @Getter getRole: (code: string) => Api.Role;

  @Mutation showSuccess;
  @Mutation showError;
  @Mutation setSelectedUsers;

  usersShown = [];

  @Prop({ default: true })
  showFilters: boolean;

  @Prop({ default: false })
  areAdminLevel1: boolean;

  statusFilter: Api.UserStatus[] = [];

  search = "";
  filters = {
    status: [],
    referent: null,
  };

  loading = false;

  editingUser = null;
  showManageUserReferentModal = null;

  sendingEmailUser = null;
  showSendingEmailModal = null;
  sendingEmailSubject = "";
  sendingEmailText = "";

  status = [];
  userStatus = null;
  changingStatusUser = null;
  showChangingStatusUserModal = null;

  resettingPasswordUser = null;
  showResettingPasswordUserModal = null;

  suspendingUser = [];
  showSuspendingUserModal = null;

  deletingUser = [];
  showDeletingUserModal = null;

  headers = [
    {
      text: "Utente",
      align: "start",
      sortable: true,
      value: "name",
    },
    {
      text: "Referente",
      align: "start",
      sortable: false,
      value: "referent",
    },
    {
      text: "Tipologia utente",
      align: "start",
      sortable: false,
      value: "status",
    },
    { text: "Azioni", value: "actions", sortable: false, filterable: false },
  ];
  selected = [];

  mounted() {
    this.initFilters();
    if (this.areAdminLevel1) {
      this.headers = [
        {
          text: "Utente",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Azioni",
          value: "actions",
          sortable: false,
          filterable: false,
        },
      ];
    }
  }

  initFilters() {
    this.statusFilter.push(this.getStatus(StatusCodes.ADMIN2_RES_AREA));
    this.statusFilter.push(this.getStatus(StatusCodes.ADMIN2_RES_SER));

    this.filters.status = _.map(this.statusFilter, "code");
  }

  initManageReferent(user) {
    this.editingUser = user;
    this.showManageUserReferentModal = true;
    if (!_.isNil(user.facilitator)) {
      this.setSelectedUsers([user.facilitator]);
    } else {
      this.setSelectedUsers([]);
    }
  }

  capitalize(text) {
    return _.capitalize(text);
  }

  filterUsers() {
    let textFilteredUsers = this.users;
    if (!!this.search && this.search.length > 0) {
      textFilteredUsers = this.users.filter((u) => {
        if (
          u.name.toLowerCase().includes(this.search.toLowerCase()) ||
          u.surname.toLowerCase().includes(this.search.toLowerCase()) ||
          u.email.toLowerCase().includes(this.search.toLowerCase())
        ) {
          return true;
        }
        return false;
      });
    }

    const statusFilteredUsers = _.filter(this.users, (user) => {
      return checkUserStatus(user, this.filters.status);
    });

    let referentFilteredUsers = this.users;
    if (!_.isNil(this.filters.referent)) {
      referentFilteredUsers = _.filter(this.users, (user) => {
        return user.facilitator?.guid == this.filters.referent.guid;
      });
    }

    this.usersShown = _.intersectionBy(
      textFilteredUsers,
      statusFilteredUsers,
      referentFilteredUsers,
      "guid"
    );
  }

  getRolesFilter() {
    return [this.getRole(RoleCodes.ADMIN1).guid];
  }

  hasReferentName(user) {
    return !_.isEmpty(getReferentName(user));
  }

  getReferentName(user) {
    if (this.hasReferentName(user)) {
      return getReferentName(user);
    }
    return "Nessun referente";
  }

  getRoleTypeLabel(user) {
    if (checkUserStatus(user, [StatusCodes.ADMIN2_RES_AREA])) {
      return "Responsabile Area";
    }
    if (checkUserStatus(user, [StatusCodes.ADMIN2_RES_SER])) {
      return "Responsabile Servizi";
    }
  }

  setSending(item) {
    this.sendingEmailUser = item;
    this.showSendingEmailModal = item;
  }

  async sendEmail() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation sendEmailToUser(
          $guid: ID!
          $subject: String!
          $text: String!
        ) {
          sendEmailToUser(guid: $guid, subject: $subject, text: $text)
        }
      `,
      variables: {
        guid: this.sendingEmailUser.guid,
        subject: this.sendingEmailSubject,
        text: this.sendingEmailText,
      },
    });
    this.loading = false;
    this.setSending(null);
    if (res.data.sendEmailToUser) {
      this.showSuccess("Email inviata correttamente");
      this.sendingEmailSubject = "";
      this.sendingEmailText = "";
    } else {
      this.showError("Errore invio email");
    }
  }

  setResettingPassword(item) {
    this.resettingPasswordUser = item;
    this.showResettingPasswordUserModal = item;
  }

  handleClick(item) {
    this.$router.push({
      name: RoutesNames.manageUsersDetails,
      params: { userGuid: item.guid },
    });
  }

  async resetUserPassword() {
    this.loading = true;
    const res = await this.$apollo.mutate({
      mutation: gql`
        mutation resetPassword($guid: ID!) {
          resetPassword(guid: $guid)
        }
      `,
      variables: { guid: this.resettingPasswordUser.guid },
    });
    this.loading = false;
    this.setResettingPassword(null);
    if (res.data.resetPassword) {
      this.showSuccess("Password reset completato");
    } else {
      this.showError("Errore reset password");
    }
  }

  showChangeStatusModal(item) {
    this.changingStatusUser = item;
    this.showChangingStatusUserModal = item;
  }

  setSuspending(items) {
    this.suspendingUser = items;
    this.showSuspendingUserModal = items.length == 0 ? null : items;
  }

  setDeleting(items) {
    this.deletingUser = items;
    this.showDeletingUserModal = items.length == 0 ? null : items;
  }

  updateUsers() {
    this.$emit("update");
  }

  isSuspended(code) {
    return isSuspended(code);
  }

  isDeleted(code) {
    return isDeleted(code);
  }
}
